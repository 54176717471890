import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/gaurds/auth.gaurd';
import {ViewPageEnum} from "./core/view-models/view-page-enum";
import {ViewAuthGuard} from "./core/gaurds/view-auth-guard.service";
import {AuthorizationSpace} from "./core/models/app/authorizationNameSpace";
import PagesEnum = AuthorizationSpace.PagesEnum;
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/home-screens/home-main-screen/home-main-screen.module').then(m => m.HomeMainScreenModule),
    data: { title: 'DASHBOARD', icon: 'map', canStandalone: true , pageType:PagesEnum.DASHBOARD},
    canActivate: [AuthGuard]
  },
  {
    path: 'gmao',
    loadChildren: () => import('./pages/ticket/gmao-tickets-dashboard.module').then(m => m.GmaoTicketsDashboardModule),
    data: { title: 'GMAO_TICKETS', icon: 'view_list', pageType:PagesEnum.GMAO },
    canActivate: [AuthGuard]
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./pages/ticket/gmao-tickets-dashboard.module').then(m => m.GmaoTicketsDashboardModule),
    data: { title: 'GMAO_TICKETS', icon: 'view_list', pageType:PagesEnum.GMAO },
    canActivate: [AuthGuard]
  },
  {
    path: 'gmao-indicators',
    loadChildren: () => import('./pages/ticket-indicators/ticket-indicators.module').then(m => m.TicketIndicatorsModule),
    data: { title: 'GMAO_INDICATORS', icon: 'dashboard', pageViewType:ViewPageEnum.GMAO, pageType:PagesEnum.GMAO},
    canActivate: [AuthGuard]
  },
  {
    path: 'interventions',
    loadChildren: () => import('./pages/interventions/interventions.module').then(m => m.InterventionsModule),
    data: {title: 'INTERVENTIONS_TICKETS', icon: 'view_list', pageType:PagesEnum.INTERVENTIONS},
    canActivate: [AuthGuard]
  },
  {
    path: 'interventions-indicators',
    loadChildren: () => import('./pages/interventions-indicators/interventions-indicators.module').then(m => m.InterventionsIndicatorsModule),
    data: {title: 'INTERVENTIONS_INDICATORS', icon: 'dashboard', pageViewType:ViewPageEnum.INTERVENTIONS, pageType:PagesEnum.INTERVENTIONS},
    canActivate: [AuthGuard]
  },
  {
    path: 'interventions/:id',
    loadChildren: () => import('./pages/interventions/interventions.module').then(m => m.InterventionsModule),
    data: {title: 'INTERVENTIONS_TICKETS', icon: 'view_list', pageType:PagesEnum.INTERVENTIONS},
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/building-equipment',
    loadChildren: () => import('./pages/admin/equipment/equipment.module').then(m => m.EquipmentModule),
    data: { title: 'EQUIPMENTS', icon: 'hvac' , pageType:PagesEnum.EQUIPMENTS},
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
    data: { title: 'MAINTENANCE', icon: 'handyman' , pageType:PagesEnum.MAINTENANCE},
    canActivate: [AuthGuard]
  },
  {
    path: `explorer`,
    loadChildren: () => import('./pages/document-explorer/document-explorer.module').then(m => m.DocumentExplorerModule),
    data: { title: 'EXPLORER', icon: 'folder' , pageType:PagesEnum.EXPLORER},
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/bills',
    loadChildren: () => import('./pages/bills-screens/bills/bills.module').then(m => m.BillsModule),
    data: { title: 'BILLS', icon: 'request_quote' , pageType:PagesEnum.BILLS},
    canActivate: [AuthGuard]
  },
  {
    path: `admin/expenses`,
    loadChildren: () => import('./pages/bills-screens/expenses/expenses.module').then(m => m.ExpensesModule),
    data: { title: 'EXPENSES', icon: 'euro_symbol' , pageType:PagesEnum.EXPENSES},
    canActivate: [AuthGuard]
  },
  {
    path: 'reports-templates',
    loadChildren: () => import('./pages/reports-screens/reports-templates/reports-templates.module').then(m => m.ReportsTemplatesModule),
    data: { title: 'REPORTS_TEMPLATES', icon: 'document_scanner', pageType:PagesEnum.REPORTS },
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports-screens/reports/reports.module').then(m => m.ReportsModule),
    data: { title: 'REPORTS', icon: 'web', pageType:PagesEnum.REPORTS },
    canActivate: [AuthGuard]
  },
  {
    path: 'sites',
    loadChildren: () => import('./pages/sites/sites.module').then(m => m.SitesModule),
    data: { title: 'SITES', icon: 'apartment', pageType:PagesEnum.SITES },
    canActivate: [AuthGuard]
  },
  {
    path: 'sites/:id',
    loadChildren: () => import('./pages/sites/sites.module').then(m => m.SitesModule),
    data: { title: 'SITES', icon: 'apartment', pageType:PagesEnum.SITES },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/users-access`,
    loadChildren: () => import('./pages/users-access/users-access.module').then(m => m.UsersAccessModule),
    data: { title: 'USERS', icon: 'groups' , pageType:PagesEnum.USERS},
    canActivate: [AuthGuard]
  },
  {
    path: 'view-controller',
    loadChildren: () => import('./pages/site-views/site-views.module').then(m => m.SiteViewsModule),
    data: { title: 'VIEWS', icon: 'widgets' , pageType:PagesEnum.VIEWS},
    canActivate: [AuthGuard]
  },
  {
    path: `admin/alarms`,
    loadChildren: () => import('./pages/alarms/alarm.module').then(m => m.AlarmsModule),
    data: { title: 'ALARMS', icon: 'alarm' , pageType:PagesEnum.ALARMS},
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/energy-meters',
    loadChildren: () => import('./pages/energy-screens/energy-meters/energy-meters.module').then(m => m.EnergyMetersModule),
    data: { title: 'ENERGY_METERS', icon: 'electric_meter' , pageType:PagesEnum.ENERGY},
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/energy-meters/:id',
    loadChildren: () => import('./pages/energy-screens/energy-meters/energy-meters.module').then(m => m.EnergyMetersModule),
    data: { title: 'ENERGY_METERS', icon: 'electric_meter' , pageType:PagesEnum.ENERGY},
    canActivate: [AuthGuard]
  },
  {
    path: `admin/energy-consumption`,
    loadChildren: () => import('./pages/energy-screens/energy-consumption/energy-consumption.module').then(m => m.EnergyConsumptionModule),
    data: { title: 'ENERGY_CONSUMPTION', icon: 'monetization_on' , pageType:PagesEnum.ENERGY},
    canActivate: [AuthGuard]
  },
  {
    path: `admin/energy-consumption/building-consumption/:id`,
    loadChildren: () =>
      import('./pages/energy-screens/energy-consumption-evaluation/energy-consumption-evaluation.module').then(m => m.EnergyConsumptionEvaluationModule),
    data: { title: 'ENERGY_SITE_INSIGHTS', icon: 'insights', pageType:PagesEnum.ENERGY },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/energy-consumption-analysis`,
    loadChildren: () => import('./pages/energy-screens/energy-consumption-analysis/energy-consumption-analysis.module').then(m => m.EnergyConsumptionAnalysisModule),
    data: { title: 'ENERGY_CONSUMPTION_ANALYSIS', icon: 'query_stats', pageType:PagesEnum.ENERGY },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/energy-consumption-analysis/:id`,
    loadChildren: () =>
      import('./pages/energy-screens/energy-consumption-analysis/energy-consumption-analysis.module').then(
        m => m.EnergyConsumptionAnalysisModule
      ),
    data: { title: 'ENERGY_CONSUMPTION_ANALYSIS', icon: 'query_stats', pageType:PagesEnum.ENERGY },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/water-consumption`,
    loadChildren: () =>
      import('./pages/energy-screens/water-consumption/water-consumption.module').then(m => m.WaterConsumptionModule),
    data: { title: 'WATER_CONSUMPTION', icon: 'monetization_on', pageType:PagesEnum.ENERGY },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/water-consumption/water-building-consumption/:id`,
    loadChildren: () =>
      import('./pages/energy-screens/water-consumption-evaluation/water-consumption-evaluation.module').then(m => m.WaterConsumptionEvaluationModule),
    data: { title: 'WATER_SITE_INSIGHTS', icon: 'insights', pageType:PagesEnum.ENERGY },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/water-consumption-analysis`,
    loadChildren: () =>
      import('./pages/energy-screens/water-consumption-analysis/water-consumption-analysis.module').then(
        m => m.WaterConsumptionAnalysisModule
      ),
    data: { title: 'WATER_CONSUMPTION_ANALYSIS', icon: 'query_stats', pageType:PagesEnum.ENERGY },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/meters-contracts-management',
    loadChildren: () => import('./pages/energy-screens/meters-contracts-management/meters-contracts-management.module').then(m => m.MetersContractsManagementModule),
    data: { title: 'ENERGY_CONTRACTS', icon: 'draw' , pageType:PagesEnum.ENERGY},
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/meters-contracts-management/:id',
    loadChildren: () => import('./pages/energy-screens/meters-contracts-management/meters-contracts-management.module').then(m => m.MetersContractsManagementModule),
    data: { title: 'ENERGY_CONTRACTS', icon: 'draw' , pageType:PagesEnum.ENERGY},
    canActivate: [AuthGuard]
  },
  {
    path: `user-profile`,
    loadChildren: () =>
      import('./pages/user-profile/user-profile.module').then(
        m => m.UserProfileModule
      ),
    data: { title: 'PROFILE', icon: 'person', pageType:'ALLOWED' },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/scheduling`,
    loadChildren: () =>
      import('./pages/scheduling-screens/scheduling/scheduling.module').then(
        m => m.SchedulingModule
      ),
    data: { title: 'SCHEDULING', icon: 'edit_calendar', pageType:PagesEnum.SCHEDULING },
    canActivate: [AuthGuard]
  },
  {
    path: `admin/planning-templates`,
    loadChildren: () =>
      import('./pages/scheduling-screens/scheduling-planning-templates/scheduling-planning-templates.module').then(
        m => m.SchedulingPlanningTemplatesModule
      ),
    data: { title: 'SCHEDULING', icon: 'alarm_add', pageType:PagesEnum.SCHEDULING },
    canActivate: [AuthGuard]
  },
  {
    path: `ifttt-templates`,
    loadChildren: () =>
      import('./pages/ifttt/ifttt-templates/ifttt-templates.module').then(
        m => m.IftttTemplatesModule
      ),
    data: {title: 'SCENARIO_TEMPLATES', icon: 'settings', canStandalone: true, pageType: PagesEnum.SCENARIO},
    canActivate: [AuthGuard]
  },
  {
    path: `ifttt-scenarios`,
    loadChildren: () =>
      import('./pages/ifttt/ifttt-scenarios/ifttt-scenarios.module').then(
        m => m.IftttScenariosModule
      ),
    data: {title: 'SCENARIO', icon: 'merge', canStandalone: true, pageType: PagesEnum.SCENARIO},
    canActivate: [AuthGuard]
  },
  {
    path: `ifttt-scheduling`,
    loadChildren: () =>
      import('./pages/ifttt/ifttt-scheduling/ifttt-scheduling.module').then(
        m => m.IftttSchedulingModule
      ),
    data: {title: 'SCENARIO_PLANNING', icon: 'edit_calendar', pageType: PagesEnum.SCENARIO},
    canActivate: [AuthGuard]
  },
  /******************* standalone page*************/
  {
    path: 'stand-alone',
    loadChildren: () => import('./pages/stand-alone/stand-alone.module').then(m => m.StandAloneModule),
    data: {canStandalone: true}
  },
  {
    path: 'standalone-home-page/:siteId',
    loadChildren: () => import('./pages/standalone-home-page/standalone-home-page.module').then(m => m.StandaloneHomePageModule),
    data: {canStandalone: true},
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment-scada/:siteId',
    loadChildren: () => import('./pages/home-screens/equipment-scada-dashboard/equipment-scada-dashboard.module').then(m => m.EquipmentScadaDashboardModule),
    data: {canStandalone: true},
    canActivate: [AuthGuard]
  },
  {
    path: 'equipment-control/:siteId',
    loadChildren: () => import('./pages/home-screens/equipment-control-dashboard/equipment-control-dashboard.module').then(m => m.EquipmentControlDashboardModule),
    data: {canStandalone: true},
    canActivate: [AuthGuard]
  },
  {
    path: 'site-equipment/:siteId',
    loadChildren: () => import('./pages/admin/equipment/equipment.module').then(m => m.EquipmentModule),
    data: {canStandalone: true, pageType:PagesEnum.EQUIPMENTS},
    canActivate: [AuthGuard]
  },
  {
    path: `energy-consumption/building-consumption/:id`,
    loadChildren: () =>
      import('./pages/energy-screens/energy-consumption-evaluation/energy-consumption-evaluation.module').then(m => m.EnergyConsumptionEvaluationModule),
    data: {canStandalone: true, pageType:PagesEnum.ENERGY},
    canActivate: [AuthGuard]
  },
  {
    path: `energy-consumption-analysis/:id`,
    loadChildren: () =>
      import('./pages/energy-screens/energy-consumption-analysis/energy-consumption-analysis.module').then(
        m => m.EnergyConsumptionAnalysisModule
      ),
    data: {canStandalone: true, pageType:PagesEnum.ENERGY},
    canActivate: [AuthGuard]
  },
  {
    path: `ifttt-scheduling/:id`,
    loadChildren: () =>
      import('./pages/ifttt/ifttt-scheduling/ifttt-scheduling.module').then(
        m => m.IftttSchedulingModule
      ),
    data: {canStandalone: true, pageType: PagesEnum.SCENARIO},
    canActivate: [AuthGuard]
  },


  /******************* Dashboard views*************/
  {
    path: ':siteId/schemas/:name',
    loadChildren: () => import('./pages/home-screens/schema-dashboard/schema-dashboard.module').then(m => m.SchemaDashboardModule),
    data: { featureType: 'VIEW',icon: 'map' }
  },
  {
    path: ':siteId/equipment-scada/:name',
    loadChildren: () => import('./pages/home-screens/equipment-scada-dashboard/equipment-scada-dashboard.module').then(m => m.EquipmentScadaDashboardModule),
    data: {featureType: 'VIEW',icon: 'map'},
    canActivate: [ViewAuthGuard]
  },
  {
    path: ':siteId/equipment-control/:name',
    loadChildren: () => import('./pages/home-screens/equipment-control-dashboard/equipment-control-dashboard.module').then(m => m.EquipmentControlDashboardModule),
    data: {featureType: 'VIEW',icon: 'map'},
    canActivate: [ViewAuthGuard]
  },
  {
    path: ':siteId/alarm-visualisation',
    loadChildren: () => import('./pages/home-screens/alarms-dashboard/alarms-visual-dashboard.module').then(m => m.AlarmsVisualDashboardModule),
    data: { featureType: 'VIEW',icon: 'map' }
  },
  {
    path: ':siteId/informations',
    loadChildren: () => import('./pages/home-screens/site-information-dashboard/site-information-dashboard.module').then(m => m.SiteInformationDashboardModule),
    data: { featureType: 'VIEW',icon: 'map' }
  },
  {
    path: ':siteId/reporting-view/:name',
    loadChildren: () => import('./pages/home-screens/reporting-dashboard/reporting-dashboard.module').then(m => m.ReportingDashboardModule),
    data: { featureType: 'VIEW',icon: 'map' }
  },
  {
    path: ':siteId/reporting-view/w/:name',
    loadChildren: () => import('./pages/home-screens/reporting-dashboard/reporting-dashboard.module').then(m => m.ReportingDashboardModule),
    data: { featureType: 'VIEW',icon: 'map' }
  },
  {
    path: ':siteId/dashboard',
    loadChildren: () => import('./pages/home-screens/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { featureType: 'VIEW', icon: 'map' }
  },
  /******************************** ***********************/




  /******************* reception pages *************/
  {
    path: `reception-window`,
    loadChildren: () => import('./pages/reception-window/reception-window.module').then(m => m.ReceptionWindowModule),
    data: { title: 'RECEPTION' },
    canActivate: [AuthGuard]
  },
  {
    path: `weather-window`,
    loadChildren: () => import('./pages/weather-window/weather-window.module').then(m => m.WeatherWindowModule),
    data: { title: 'WEATHER_STATION' },
    canActivate: [AuthGuard]
  },
  /******************************** ***********************/




  /******** New Equipments page v2   *********/
  {
    path: 'site-equipments',
    loadChildren: () => import('./pages/site-equipments/site-equipments.module').then(m => m.SiteEquipmentsModule),
    data: { title: 'EQUIPMENTS' },
    canActivate: [AuthGuard]
  },
  {
    path: 'site-equipments/:id',
    loadChildren: () => import('./pages/site-equipments/site-equipments.module').then(m => m.SiteEquipmentsModule),
    data: { title: 'EQUIPMENTS' },
    canActivate: [AuthGuard]
  },
  /******************************** ***********************/

  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
